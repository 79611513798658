﻿export function hideErrorDiv(div: HTMLDivElement): void {
    div.innerText = "";
    div.classList.add("hide");
    div.setAttribute("aria-live", "off");
}

export function hideSpinner(spinnerDiv: HTMLDivElement) {
    spinnerDiv.classList.add("hide");
}

export function showErrorDiv(div: HTMLDivElement, message: string): void {
    div.classList.remove("hide");
    div.innerText = message;
    div.setAttribute("aria-live", "assertive");
}

export function showSpinner(spinnerDiv: HTMLDivElement) {
    spinnerDiv.classList.remove("hide");
}

export function toggleAccountNumberValidationMessage(
    accountNumberTextBox: HTMLInputElement, isAccountNumberValid: boolean): void {
    const accountErrorMessageDiv = document.getElementById("accountErrorMessage") as HTMLDivElement;

    if (isAccountNumberValid) {
        hideErrorDiv(accountErrorMessageDiv);
    }
    else {
        const accountNumberLengthMessage = "Account number must be at least 7 characters long";
        const accountNumberRequiredMessage = "Billing account number is required";
        let message: string;

        if (accountNumberTextBox.validity.valueMissing) {
            message = accountNumberRequiredMessage;
        }
        else if (accountNumberTextBox.validity.patternMismatch) {
            message = accountNumberLengthMessage;
        }
        else {
            message = "";
        }

        showErrorDiv(accountErrorMessageDiv, message);
    }

    accountNumberTextBox.setAttribute("aria-invalid", isAccountNumberValid ? "false" : "true");
}

export function togglePinValidationMessage(pinTextBox: HTMLInputElement, isPinValid: boolean): void {
    const pinErrorMessageDiv = document.getElementById("pinErrorMessage") as HTMLDivElement;

    if (isPinValid) {
        hideErrorDiv(pinErrorMessageDiv);
    }
    else {
        const pinRequiredMessage = "4-digit PIN is required";
        showErrorDiv(pinErrorMessageDiv, pinRequiredMessage);
    }

    pinTextBox.setAttribute("aria-invalid", isPinValid ? "false" : "true");
}
