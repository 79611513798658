﻿import { hideSpinner, showErrorDiv } from "./ui.js"
import { nonAlphanumericRegex } from "./constants.js"

const unknownErrorMessage = "There was an unexpected error that may be temporary. Please try again later.";

export async function getBalanceAsync() {
    const errorMessageDiv = document.getElementById("errMessage") as HTMLDivElement;
    const spinnerDiv = document.getElementById("spinner") as HTMLDivElement;
    const accountNumberTextBox = document.getElementById("billingAccountNumber") as HTMLInputElement;
    const accountNumber = accountNumberTextBox.value.replace(nonAlphanumericRegex, "");
    const pinTextBox = document.getElementById("customerPin") as HTMLInputElement;
    const payload = { accountnumber: accountNumber, enteredpin: pinTextBox.value };
    const apiUrl = '/api/Balance';
    const contentTypeKey = "Content-Type";
    const RequestVerificationTokenKey = "RequestVerificationToken";
    const tokenElement = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
    let token = tokenElement.value;
    let headers = new Headers();
    headers.append(contentTypeKey, "application/json");
    headers.append(RequestVerificationTokenKey, token);

    try {
        await refreshAntiForgeryTokenAsync();

        const response = await fetch(apiUrl, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(payload)
        });

        if (response.ok) {
            const responsedata = await response.json();
            const redirectUrlKey = "redirectUrl";
            hideSpinner(spinnerDiv);

            if (redirectUrlKey in responsedata) {
                window.location.href = responsedata[redirectUrlKey];
            }
            else {
                showErrorDiv(errorMessageDiv, unknownErrorMessage);
            }
        }
        else {
            hideSpinner(spinnerDiv);
            const responsedata = await response.json();
            const controllerApiErrorKey = "controllerApiError";
            var errorMessage;

            if (controllerApiErrorKey in responsedata) {
                errorMessage = responsedata[controllerApiErrorKey];
            }
            else {
                errorMessage = unknownErrorMessage;
            }

            showErrorDiv(errorMessageDiv, errorMessage);
        }
    }
    catch (error) {
        console.error('Error : ', error);
        hideSpinner(spinnerDiv);
        showErrorDiv(errorMessageDiv, unknownErrorMessage);
    }
}

export async function refreshAntiForgeryTokenAsync() {
    try {
        const apiUrl = '/api/Balance/GetNewToken'
        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        });

        const token = await response.text();
        const antiForegeryTokenElement =
            document.querySelector<HTMLInputElement>('[name = "__RequestVerificationToken"]');

        if (antiForegeryTokenElement) {
            antiForegeryTokenElement.value = token;
        }
    }
    catch (error) {
        console.error('Error:', error);
    }
}
