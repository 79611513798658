﻿import { isValidAccountNumber, isValidPin } from "./validators.js"
import { getBalanceAsync, refreshAntiForgeryTokenAsync } from "./apimethods.js"
import { nonAlphanumericRegex, nonNumericRegex } from "./constants.js"
import {
    hideErrorDiv,
    hideSpinner,
    showSpinner,
    toggleAccountNumberValidationMessage,
    togglePinValidationMessage
} from "./ui.js"

const accountErrorMessageDiv = document.getElementById("accountErrorMessage") as HTMLDivElement;
const accountNumberTextBox = document.getElementById("billingAccountNumber") as HTMLInputElement;
const errorMessageDiv = document.getElementById("errMessage") as HTMLDivElement;
const pinErrorMessageDiv = document.getElementById("pinErrorMessage") as HTMLDivElement;
const pinTextBox = document.getElementById("customerPin") as HTMLInputElement;
const spinnerDiv = document.getElementById("spinner") as HTMLDivElement;

initialize();

function deleteInvalidCharactersFromAccountNumber(accountNumber: string): string {
    const accountNumberFormatRegex: RegExp = /^[0-9]{10}/;

    if (accountNumberFormatRegex.test(accountNumber) && accountNumber.substring(10, 11).match("/")) {
        accountNumber = accountNumber.substring(0, 11) + accountNumber.substring(11, 16).replace(nonNumericRegex, "");
        accountNumber = accountNumber.substring(0, 12);
    }
    else {
        accountNumber = accountNumber?.substring(0, 16).replace(nonAlphanumericRegex, "") || "";
    }

    return accountNumber;
}

function initialize(): void {
    accountNumberTextBox.addEventListener("input", () => {
        accountNumberTextBox.value = deleteInvalidCharactersFromAccountNumber(accountNumberTextBox.value);
    });

    accountNumberTextBox.addEventListener("paste", (event: ClipboardEvent) => {
        event.preventDefault();
        const nonAlphaNumericAndBackSlashRegex = /[^0-9a-zA-Z\/]/g;
        const typeText: string = "text/plain";
        let accountNumberInput = event.clipboardData?.getData(typeText) || "";
        accountNumberTextBox.value = deleteInvalidCharactersFromAccountNumber(
            accountNumberInput.replace(nonAlphaNumericAndBackSlashRegex, ""));
    });

    accountNumberTextBox.addEventListener("blur", () => {
        let isAccountNumberValid = isValidAccountNumber(accountNumberTextBox);
        toggleAccountNumberValidationMessage(accountNumberTextBox, isAccountNumberValid);
    });

    pinTextBox.addEventListener("input", () => {
        pinTextBox.value = pinTextBox.value.replace(nonNumericRegex, "");
    });

    pinTextBox.addEventListener("blur", () => {
        let isPinValid = isValidPin(pinTextBox);
        togglePinValidationMessage(pinTextBox, isPinValid);
    });

    document.getElementById("otp-signIn")?.addEventListener("click", async (event) => {
        event.preventDefault();
        let isAccountNumberValid = isValidAccountNumber(accountNumberTextBox);
        let isPinValid = isValidPin(pinTextBox);

        toggleAccountNumberValidationMessage(accountNumberTextBox, isAccountNumberValid);
        togglePinValidationMessage(pinTextBox, isPinValid);

        if (isAccountNumberValid && isPinValid) {
            showSpinner(spinnerDiv);
            getBalanceAsync();
        }
    });

    hideErrorDiv(accountErrorMessageDiv);
    hideErrorDiv(errorMessageDiv);
    hideErrorDiv(pinErrorMessageDiv);
    hideSpinner(spinnerDiv);

    const sessionTimeout = 60000;
    setTimeout(refreshAntiForgeryTokenAsync, sessionTimeout);
}
